





































import {Component, Vue} from 'vue-property-decorator';
import Settings from "@/packages/settings/Settings";
import {Auth} from "@/packages/auth/Auth";
import {Article} from "@/views/Article/Article";

@Component
export default class ArticleForm extends Vue {
  public article = {
    id: 0,
    title: '',
    description: '',
    status: 1,
    videoUrl: '',
    type: 1,
    userId: 1
  };
  public settings: Settings;
  public id: number;

  public articleTypes = Article.TYPES;

  public constructor() {
    super();
    this.settings = new Settings();
    this.id = +this.$route.params.id;
  }

  public addArticle() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    if (this.article.id > 0) {
      this.axios.put(
          this.settings.settings.host + '/api/articles/' + this.article.id,
          {
            title: this.article.title,
            description: this.article.description,
            videoUrl: this.article.videoUrl,
            status: this.article.status,
            type: this.article.type,
            userId: this.article.userId
          },
          {
            headers: {
              Authorization: 'Bearer ' + Auth.getToken()
            }
          }
      ).then(data => {
        that.$notify({
          group: 'notify',
          type: 'success',
          text: 'Artykuł został edytowany'
        });
      });
    } else {
      this.axios.post(
          this.settings.settings.host + '/api/articles',
          {
            title: this.article.title,
            description: this.article.description,
            videoUrl: this.article.videoUrl,
            status: this.article.status,
            type: this.article.type,
            userId: this.article.userId
          },
          {
            headers: {
              Authorization: 'Bearer ' + Auth.getToken()
            }
          }
      ).then(data => {
        that.$notify({
          group: 'notify',
          type: 'success',
          text: 'Artykuł został dodany'
        });
        that.$router.push('/panel/article/list');
      });
    }
  }

  public loadData(): void {
    this.axios.get(this.settings.settings.host + '/api/articles/' + this.$route.params.id, { //this.id.toString(),{
      params: {},
      headers: {
        Authorization: 'Bearer ' + Auth.getToken()
      }
    }).then(response => {
      this.article = response.data;
    });
  }

  created(): void {
    if (this.id > 0) {
      this.loadData();
    }
  }
}
